export const AUTH_PREFIX = '/oauth';

/**
 * URL to sign in to the application.
 */
export const AUTH_SIGNIN_URL = '/oauth/signin';

/**
 * URL to sign up to the application.
 */
export const AUTH_SIGNUP_URL = '/oauth/signup';

/**
 * URL to sign out of the application.
 */
export const AUTH_SIGNOUT_URL = '/oauth/signout';

/**
 * URL to refresh access token.
 */
export const AUTH_REFRESH_URL = '/oauth/refresh';

/**
 * URL Identity Platform callback
 */
export const AUTH_CALLBACK_URL = '/oauth/callback';

/**
 * URL to reset authentication cookies.
 */
export const AUTH_RESET_URL = '/oauth/reset';

/**
 * URL to refresh access token and redirect to target.
 */
export const AUTH_REFRESH_REDIRECT_URL = '/oauth/refresh-redirect';

/**
 * URL to sign in with Google One Tap
 */
export const AUTH_SIGNIN_WITH_GOOGLE_ONE_TAP_URL = '/oauth/signin/onetap';

/**
 * URL to sign in with magic link
 */
export const AUTH_SIGNIN_WITH_MAGIC_LINK_URL = '/oauth/signin/magic-link';

/**
/**
 * URL to start impersonation
 */
export const AUTH_IMPERSONATE_START_URL = '/oauth/impersonate';

/**
 * URL to end impersonation
 */
export const AUTH_IMPERSONATE_END_URL = '/oauth/impersonate/end';
