import MobileDetect from 'mobile-detect';
import { Device } from 'ha/types/Device';

const detectDevice = (userAgent: string): Device => {
  const device = new MobileDetect(userAgent);

  return {
    // @ts-ignore
    mobile: device.mobile(),
    isIos: device.os() === 'iOS',
    iosVersion: device.version('iOS'),
    // @ts-ignore
    tablet: device.tablet(),
  };
};
export { detectDevice };
